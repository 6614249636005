import React from 'react';

export const LoadingPlaces = () => {
    return (
        <div className='alert alert-primary mt-2'>
            <h6>Buscando</h6>
            <p>Espere por favor...</p>
        </div>
    )
};
