import React, { useContext, useState } from 'react';
import { LoadingPlaces } from '.';
import { PlacesContext, MapContext } from '../context';
import { Feature } from '../interfaces/places';

export const SearchResult = () => {

    const { places, isLoadingPlaces, userLocation } = useContext(PlacesContext);
    const { map, getRouteBetweenPoints, time, distance } = useContext(MapContext);


    const [activePlaceId, setActivePlaceId] = useState('');

    const onClickPlaceClicked = ( place: Feature) => {

        const [lng, lat] = place.center
        setActivePlaceId(place.id)

        map?.flyTo({
            zoom: 14,
            center: [lng, lat] 
        })
    }

    const getRoute = (place: Feature) => {

        if( !userLocation) return;

        const [lng, lat] = place.center

        getRouteBetweenPoints(userLocation,  [lng, lat])

    }


    if( isLoadingPlaces){
        return (
            <LoadingPlaces />
        );
    }
    
    if( places.length === 0 ){
        return <></>;
    }

    const getTime = (minutes:number) : string => {
        return (
            minutes <= 60 ? `${minutes} Minutos aprox.` :
            (minutes/60) <= 24 ? `${(minutes/60).toFixed(0)} Horas aprox.` :
            (minutes/60) > 24 ? `${(minutes/60/24).toFixed(1)} Dias aprox.` :
                              `${(minutes/60/24/7)} Semanas aprox.`

        )
    }

    return <ul className='list-group mt-3'>
        {
            places.map(place => (
                <li 
                key={place.id} 
                className={`list-group-item list-group-item-action pointer ${activePlaceId === place.id ? 'active' : ''}`}
                onClick={() => onClickPlaceClicked(place)}
                >
                    <h6>{place.text}</h6>
                    <p
                        className=''
                        style={{ fontSize: '12px' }}
                    >
                        {place.place_name}
                    </p>
                    {
                        time && distance && activePlaceId === place.id &&
                        <>
                        <p style={{ fontSize: '12px' }}>Distancia: {distance} Kms</p>
                        <p style={{ fontSize: '12px' }}>Tiempo estimado: {getTime(time)} en auto.</p>
                        </>
                    }
                    <button 
                    className={`btn btn-sm ${activePlaceId === place.id ? 'btn-outline-light' : 'btn-outline-primary'}`}
                    onClick={() => getRoute(place)}
                    >
                    Direcciones</button>
                </li>
            ))
        }
    </ul>;
};
