import logoReact from '../logo.svg'

export const ReactLogo = () => {
  return (
      <img
      src={logoReact} 
      alt="logo react"
      style={{
          bottom: '20px',
          position: 'fixed',
          right: '20px',
          width: '130px'
      }}
      />
  );
};
