/* eslint import/no-webpack-loader-syntax: off */
import { useContext, useEffect, useReducer } from 'react';
//@ts-ignore
import { AnySourceData, LngLatBounds, Map, Marker, Popup } from '!mapbox-gl';

import { MapContext } from './MapContext';
import { mapReducer } from './mapReducer';
import { PlacesContext } from '../places/PlacesContext';
import { directionsApi } from '../../apis';
import { DirectionsResponse } from '../../interfaces/directions';

export interface MapState {
    isMapReady: boolean;
    map?: Map,
    markers: Marker[];
    distance?: number;
    time?: number
}


const INITIAL_STATE: MapState = {
    isMapReady: false,
    map: undefined,
    markers: [],
    distance: undefined,
    time: undefined
}

interface Props {
    children: JSX.Element | JSX.Element[]
}


export const MapProvider = ({ children }: Props) => {

    const [state, dispatch] = useReducer(mapReducer, INITIAL_STATE);
    const { places } = useContext(PlacesContext);

    useEffect(() => {

        state.markers.forEach(marker => marker.remove());

        const newMarkers: Marker[] = [];

        for (const place of places) {

            const [lng, lat] = place.center;
            const popup = new Popup()
                .setHTML(`
                <h6>${place.text}</<h6>
                <p>${place.place_name}</p>
            `);

            const newMarker = new Marker()
                .setPopup(popup)
                .setLngLat([lng, lat])
                .addTo(state.map!);


            newMarkers.push(newMarker)

        }

        dispatch({ type: 'setMarkers', payload: newMarkers })


    }, [places]);


    const setMap = (map: Map) => {

        const myLocationPopup = new Popup()
            .setHTML(`
        <h4>Aquí estoy</h4>
        <p>En algún lugar</p>
        `);

        new Marker({ color: '#61DAFB' })
            .setLngLat(map.getCenter())
            .setPopup(myLocationPopup)
            .addTo(map);


        dispatch({ type: 'setMap', payload: map })
    }

    const getRouteBetweenPoints = async (start: [number, number], end: [number, number]) => {

        const resp = await directionsApi.get<DirectionsResponse>(`/${start.join(',')};${end.join(',')}`)
        const { distance, duration, geometry } = resp.data.routes[0];
        //console.log(resp.data);

        const { coordinates: coords } = geometry;

        let kms = distance / 1000;
        kms = Math.round(kms * 100);
        kms /= 100;

        dispatch({ type: 'setDistance', payload: kms})

        const minutes = Math.floor(duration / 60);

        dispatch({ type: 'setTime', payload: minutes})

        const bounds = new LngLatBounds(
            start,
            start
        );

        for (const coord of coords) {
            const newCoords: [number, number] = [coord[0], coord[1]];
            bounds.extend(newCoords)
        }

        state.map?.fitBounds(bounds, {
            padding: 200,
        });

        //polyline
        const sourceData: AnySourceData = {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: coords
                        }
                    }
                ]
            }
        }

        if(state.map?.getLayer('RouteString')){ //si existe ruta la remuevo
                state.map.removeLayer('RouteString');
                state.map.removeSource('RouteString')
        }


        state.map?.addSource('RouteString', sourceData);
        state.map?.addLayer({
            id: 'RouteString',
            type: 'line',
            source: 'RouteString',
            layout: {
                'line-cap': 'round',
                'line-join': 'round'
            },
            paint: {
                'line-color': '#0b5ed7',
                'line-width': 3
            }
        })

    }

    return <MapContext.Provider value={{
        ...state,

        setMap,
        getRouteBetweenPoints
    }}>
        {children}
    </MapContext.Provider>;
};
