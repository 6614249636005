import { ChangeEvent, useContext, useRef } from "react";
import { SearchResult } from ".";
import { PlacesContext } from '../context/places/PlacesContext';

export const SearchBar = () => {


    const { searchplacesByTerms } = useContext(PlacesContext);

    const deboundRef = useRef<NodeJS.Timeout>();

    const onQueryChanged = (event: ChangeEvent<HTMLInputElement>) => {
        if (deboundRef.current) {
            clearTimeout(deboundRef.current)
        }

        deboundRef.current = setTimeout(() => {
            //todo: buscar o ejecutar consulta
            searchplacesByTerms(event.target.value)
            //console.log('debounced value:', event.target.value);
        }, 350);
    }



    return (
        <div className='search-container'>

            <input
                type="text"
                className="form-control"
                placeholder="Buscar lugar..."
                onChange={onQueryChanged}
            />

            <SearchResult />

        </div>
    )
};
