import React from 'react';

export const Loading = () => {
  return <div className='d-flex justify-content-center align-items-center loading-map'>
      <div className='text-center'>
      <h3>Espere por favor</h3>
        <span>Localizando...</span>
      </div>
  </div>;
};
