/* eslint import/no-webpack-loader-syntax: off */
//@ts-ignore
import { Map, Marker } from '!mapbox-gl';
import { MapState } from './MapProvider';

type MapAction =
    | { type: 'setMap', payload: Map }
    | { type: 'setMarkers', payload: Marker[] }
    | {type: 'setDistance', payload: number}
    | {type: 'setTime', payload: number}


export const mapReducer = (state: MapState, action: MapAction): MapState => {

    switch (action.type) {
        case 'setMap':
            return {
                ...state,
                isMapReady: true,
                map: action.payload
            }

        case 'setMarkers':
            return {
                ...state,
                markers: action.payload
            }
        
        case 'setDistance':
            return {
                ...state,
                distance: action.payload
            }
        
        case 'setTime':
            return{
                ...state,
                time: action.payload
            }

        default:
            return state;
    }

}